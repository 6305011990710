html{
    font-size: 15px;
}

.ck-editor__editable_inline {
    min-height: 250px;
 }

 .hide-autocomplete-safari{
    &::-webkit-contacts-auto-fill-button, &::-webkit-credentials-auto-fill-button, &::-webkit-textfield-decoration-container,
      input::-webkit-contacts-auto-fill-button,  input::-webkit-credentials-auto-fill-button, input::-webkit-textfield-decoration-container {
        visibility: hidden;
        display: none !important;
        pointer-events: none;
        position: absolute;
        right: 0;
      }
 }

 .pausal-scroll{
  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 5px;
  }
  &::-webkit-scrollbar-thumb {
      background-color: var(--purple);
  }
 }